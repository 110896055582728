// the default introducer address when none is provided by the user
export const defaultAddress = '0x0000000000000000000000000000000000000000';

export const affiliationRate = 0.01;

export const firstEthIntroducer = [
    '0xd8de1f6764e442b8763d313722e9eaee3779707e',
    '0x1d32750e8a03443f008236f7c344fc84821cf690',
    '0xa5a6d551ab33c3920848844b3fe3b27591df8f10',
    '0xd8068f813c45c88cebb02cbc0f91f95626ec9a34',
    '0x45ee0a0db0e775aecbebb300ccfc6486690b3287',
    '0xd419d53817e1daa7b2f72e51707774f3ff0a54a3',
    '0x1080355c93a1b4c0dd3c340eed4f7e514c583077',
    '0x0013aa2fb5ec916660b38f1d53d4fc9bf8ef8a84',
    '0x78ce6849d00c2f22aec93d29144fc68366cf0c62',
    '0x60dc4b3d8e8d2c5449186270f385a56a21bb82c3',
    '0x380566185c87ab93ca4edd474cf6998b5fc4153e',
    '0x6a61dc52015945dfc6cd0c42f94a722d5022e3d1',
    '0x520e74218a9fd5563855f11d204810281a833e0f',
    '0x27c72e4bd23c910218d8f06c4a1742e06657c874',
    '0xd307ff28710b7a4587200c5ba4c67648c1f24045',
    '0x9498db340a3ecab7bb0973ee36e95e58c8e58a41',
    '0xe552821ee85284249c71d4abfc4437992ed2fb93',
    '0xc78a748dccbd806ae782eb3b8590ae7d162e5a90',
    '0x20edd77ea8582f4a2400d3c0d53154a74a1ca887',
    '0xeef2e4a5f6a01d5fb89f38211fb4e6a8702d33b6',
    '0x531c2607862de00604f600cff6be9cb312467570',
    '0xf22b286fda7369255376742f360ffcee4e1fbd42',
    '0xb35e0bfec9facd8f2dcc0e43e68350d5e7baa82f',
    '0x7b4d4ba1070c4562c568fed6280c800a68fa5427',
    '0x81042f7f99a86d3981308e72feb36bd791c31920',
    '0xb98df9f7cdc3a983081cefa1cd94aed4e936f1a9',
    '0x5b6c1f8838b9abf16c0383b7e4f3c5a750707af2',
    '0x711b3ca2cc9d5a827f83d5ceafcb0925f2fb82b3',
    '0x8e7fcc7a17f00f0f5f85f2d5964a581d0813c8a6',
    '0x6b5e2758bdb9ed798acbfd64104c1deaf09cd24b',
    '0x55763d6db54736084c1b8d010aa1d99f0dc6d07c',
    '0xfadf736b86f09f2d07d2b655cfc848fe215140d4',
    '0x9f4f9e15a4a963a9a3885979cc64b326dcaa18a8',
    '0x0def9b4811e4c4e9da5acf82d421c97b5e9c152f',
];

const secondEthIntroducer = [
    // first lockdrop participent.
    '0x0def9b4811e4c4e9da5acf82d421c97b5e9c152f',
    '0xd8068f813c45c88cebb02cbc0f91f95626ec9a34',
    '0x00eea078f8fbfc5d62458584e95306e60c4a638e',
    '0x116fd435e3b2e57598353089ba95e6a0febdb2f3',
    '0xd307ff28710b7a4587200c5ba4c67648c1f24045',
    '0x8e7fcc7a17f00f0f5f85f2d5964a581d0813c8a6',
    '0xe552821ee85284249c71d4abfc4437992ed2fb93',
    '0x02c2b66dc9678ba1441d384300299783aae5c2c1',
    '0x12909009d651d40d6ae00b150db3107bc5654603',
    '0xe0e0b97949687e5cdc9ca843c0428bd0437e176d',
    '0xd419d53817e1daa7b2f72e51707774f3ff0a54a3',
    '0xdc1f0c07202629be59e1f5396e7d78803423650e',
    '0xdbfce5f51e67be01ce77b54766f3770992878263',
    '0x9999999ccd21cf1517e907b535dc8507f79bce09',
    '0xc55022673882d57ad13358567e62025467f5a57f',
    '0x531c2607862de00604f600cff6be9cb312467570',
    '0xaf1b057a6011583171fb1ea6e627babd6ba0cab4',
    '0xc8145caf71b4af6c6cc682fc7e1a41c8769c6e86',
    '0xb86617a3e305e53eb860d3b9fe9999fe72e04767',
    '0x95600d3c264e5b6b5bf0a7b346450e73f5cd972e',
    '0x1d32750e8a03443f008236f7c344fc84821cf690',
    '0x286755cf1b65af2d10613b58f4c2e7792a7cccbe',
    '0x7f0d238412e75e45cb69b3cacd4b59f220da2442',
    '0x10534d4e612071e6b6480c498dd8bdb8f51b9ed7',
    '0xc8e99360d5b672d66610b0db0807611fe954ccf9',
    '0x00fdbb477d1cd363c333eac93fe7eb0397acd470',
    '0x7ba00c82c5b19b2ec77836b23a2289fb32558e88',
    '0xd5aa581ee5d9033b2e36811869781043b1d571a6',
    '0xbdde562d6c0a3440806e13fd69d6e28c0cb452ca',
    '0x6c7e7fbfa69f4b5a5ade1c27bc74fa4290a3b8a3', // Chinese ambassador.
    '0x1fef0f8a5ac51790de232f0ae3e1bfa47eadbacd',
    '0xaec05c2a88f2b7896005086adbaba3cfd81b63be',
    '0x6b924183307ef2e529db662f80200611328a0cbd',
    '0x880c4f74c3d4c39d75f8e2ad958f40671416bf66',
    '0x155ef6e396f63501804c192f80d223fd618b9795',
    '0xd8de1f6764e442b8763d313722e9eaee3779707e',
    '0x9a72b97137065b3816d091310e483811baa1be0f',
    '0x29c176bc50433d65113a2e6776b595a3de04f92f',
    '0xd997ca66e558aa3d8d2ec39f3c5196fe5f31fede',
    '0x61d0a005725b5a742d232655a7e22efb7cad3414',
    '0xd3320c2b9ec1d41b8fdc8884ea59b660302985ca',
    '0x50873df6f51369746638a4df373aef4b9e053fee',
    '0x81042f7f99a86d3981308e72feb36bd791c31920',
    '0xe81ff5a7f528c660cb41facedd53485e20ea2758',
    '0x7136adc702e26c13158284c4b0fcff66fa52c99b',
    '0x164767126f2570a427e3c2f34da27003f8394a40',
    '0xa8f89819ab9c31dc5b2b33caaf8de1dca0a7fea9',
    '0x274d5d04597a6c86a5a8cb9dd25c3035af6f2d12',
    '0xe32c5673b152571e57976bf3a75068917da2da9c',
    '0x72df03913ed789b45bfb04a9d6b399b8ce2a133e',
    '0x1cade4c72ccd35870c6d917bfacc2bc7cc0734c5',
    '0x80844fb6785c1eab7671584e73b0a2363599cb2f',
    '0x0fbb89875a0cb732437b63740f4d4f265ac727f1',
    '0x08fde66bf84c45fe0ca982425882abe7168c1c2c',
    '0x61e15f0bf503f38bc3de9ca4e7c1d2245a94dd6f',
    '0xd617cc373ecf63c592b44ca76290290bb7321c9f',
    '0x39d76f41cc6c17e21017dad9abc6609cacd5af67',
    '0xcb2e279b6846f8263b1559504d1773acf835e0bb',
    '0xffb3468b6861ba7044fcf61a2d976028204efc49',
    '0x900a30257db838d82130980497bd403adaf5e441',
    '0x7b22cee637f699f5792348d0085832d4312b2bd7',
    '0xdb422897f0f0d3f33845545f691eac4501799a4d',
    '0x2411605a576fe058483382c1db691318056ebaad',
    '0xe98407caac216cebe793e577930b8b4ca7a6ad61',
    '0xb4b10f0b0ac5a8e2b613d3dbe9505a07e3781128',
    '0x296f0063bdd07be8cba2f5183592574e0d62efca',
    '0xaad9a85cc1df4e36ed208420cd81a5b3acf984b6',
    '0x16617c3dc6cb3d3adc5471153520cb9c9a4a0498',
    '0x5a24f5764fc0a5fd7d74856ac25084bec0916f7c',
    '0x11a7c436365766b4a1500219529bb94a36c3f6f4',
    '0x46808e14ada3d28e0a09ce365b281254c6b8c6fa',
    '0x91715128a71c9c734cdc20e5edeeea02e72e428e',
    '0x0c4ca96d4e63af64fb7896ab41e0aa871a59fabb',
    '0x74630370197b4c4795bfeef6645ee14f8cf8997d',
    '0x8284048672d74d8165f7e38c5c0e6f8fde18c98c',
    '0x72f843eba7bc13185f9454df5b6194d3d81d2a4b',
    '0x05a0fa3b8f65c62a77e388dabaf2ec7176c3635e',
    '0x90fdff26419fb9550a6a55c792b14122e72714da',
    '0x13fcb7b29f135d3740ca8c6e3f775df6994a3d8e',
    '0xf46a707e36e82e8875d95db044d61d946decbfd7',
    '0x9628be77fb3dcd890fd93a5d039092fca855d3cc',
    '0xaadb9ed84fe0812b87ca98341aef5599b47ea60f',
    '0x2f0c01465d38fc4a31c95b7e74a4c80be6d87bbe',
    '0x5023732c23c04bbccce4c75ae9da307a74478dc3',
    '0x7a4caa3526f80793d6c11d9398efc1686f239e35',
    '0xe4bb14f0c5e772896ffa5007ea24a5109d910c8e',
    '0x327dc3388a5138a76568042a2f60bab46b086cdf',
    '0x06608f5ad0101bdf2786a6a47fc694c88a625a73',
    '0x17e35b700e6d014d9cc4cc093d6eff6e66d9f117',
    '0xb16ee2a981cce4c4c075b2bd9406d5074da02727',
    '0xbd8152fc7f060c6555809f6eada3ce64cba4d723',
    '0xaf95c1022817a47d23ac90da77be788d66ccfa69',
    '0x24f94fcd6bfe7a8ca2f9a0730dab0e91590c9147',
    '0xc1c7f7169b6c3022d172302a32d59c90a33230aa',
    '0xe5fdedd718bdf40904d67658fa4b9629a7a8b515',
    '0x4a491bc9abd9ae4f335a074a00c87da5d1f9cbd1',
    '0x574319e245428c9f064006e49ad5f83947f763ec',
    '0x2589b11029bd58ca8d917e2ba9f062dec946b463',
    '0x0a338782562e1740b9468f0855dd4c51f29defde',
    '0x38bc00ea43ebb5ef5150593a0ba6c381803717e2',
    '0xe91aee4906c8635bf15962a639436f40780233a0',
    '0x3385dc8e3ea5a0b077678a7ffdddb2bbed7142b8',
    '0x2f6f830e90baaf06575d7102f9cd83cf7880e101',
    '0x19ee7696d2a71548a7f1fc9cef644460d7e73297',
    '0x198a123599e65140bfefa096648d968a67c3b807',
    '0x943d56d95613984e4972ae0c4edf1471502a248e',
    '0x9a94771e7e73f9d8d6e880cfb12cab4e9573c45e',
    '0xff3fc772434505abff38eecde3c689d4b0254528',
    '0x6f9393ebeb1eae46417b915bec7849d270d44d57',
    '0x965e5c422913310d4b0929d3349cb6ba2c7c9d9f',
    '0x35dcf2c2223f335266e17dacbe8777d7c5bf8a15',
    '0x61c11f9f831186b8da28978220ae7827cdeddf83',
    '0x0c1633e0ba164a9def56d86d7c46afcbe4d28a77',
    '0x9ed176365cc9928d492218f837aae08019f1f465',
    '0x184cca7d9e77d07fe76450019b0df461cf83f316',
    '0xe5ee62d72a8b26cc1c6c8c2aa0d9697c3f766893',
    '0x057dc43af6187a931e78a56b3293912a5b42e5ec',
    '0x8dc9a800a12a6f0b8e0cb485213b777c296a9536',
    '0x9cc30a6b421c4e6be8547b8d694a46adf45e8f87',
    '0xbc3639460ec1e13024c87011a169db2dd07e0d7e',
    '0x157d9ff7ddd8d426a0013c682dd1567f074f6ec7',
    '0x25dba4fe43f3e630bbf5c675568c3d463f5b870c',
    '0x30086c39930c0708b83e2c31ee080505d843ac8c',
    '0x762bb60be9460754dff50275d23e742e33844491',
    '0x381150118f049e8970e57b06a6e880b10769e745',
    '0x34c3a5ea06a3a67229fb21a7043243b0eb3e853f',
    '0x4260e8206c58cd0530d9a5cff55b77d6165c7bcd',
    '0x5459fd1a4b2b798aa742223897a43a67e904f629',
    '0xccb12611039c7cd321c0f23043c841f1d97287a5',
    '0xab38a0d96586c0f536b09339daa61c4e6981d3fd',
    '0x37ad9f5cb15867156bd75e76b8d9edc40fc077a8',
    '0xcddc876165579bddbebf2ea6ddacaa54896b2b6a',
    '0x3fd1ddf28f604cfe5fefa3adc38e38be2f7208d6',
    '0x6f04ee370184c6ed094f85a0132358f6352fb7db',
    '0x51340435b04c3e8d33d7cc0d10f6ad076da50ae9',
    '0x4718fc7e40092a9d5fc584699aea1aa4c6f63d33',
    '0x33f3341d95adefed3a51358b04b599862f0f2b8c',
    '0xbd7c0a61406df33a22eb055643316adce6145198',
    '0x757058fa2a6261ce0834782152413609ca86c9be',
    '0xf59c491c98c0268480c0c4661b082961f8029fb0',
    '0x6dbc25e9ccb0995b935a4107953ba98563ed6c6f',
    '0x2ecd81e43c1f66185446f4af7dfeaa6aae249f55',
    '0x4f47f2eb86153d036a18f4858970a8ec7d51066d',
    '0xde45ac1ddb50d0c7ddb7ce66b3af6a696aec6035',
    '0xaf5d6846a4b7c2cdfabebf001382bc9cba4dd266',
    '0xf3b02c6df19e6d1fad2ce2b5a463c3cb253194e1',
    '0xe2e4f2a725e42d0f0ef6291f46c430f963482001',
    '0x376e21b6eab3ad3623e4002612c5196c4edfef2a',
    '0x30f2db9deb67121593c2909b5948d68b9fef57c7',
    '0x4af013afbadb22d8a88c92d68fc96b033b9ebb8a',
    '0xf4e2fcd72b667b9f9e71bce82069cd174348fd90',
    '0x974a3cb54d84b22a6dbeb1bf4e9546eddd825003',
    '0x6a9f813fb3e6a8f7013dabd1695bae1d49ae8481',
    '0x3a5444d841f540fc23c9fae2b5d1fb7ee0838a06',
    '0x04e6839909b7330dc28bf267230d28da5e83bd2a',
    '0x32057597286efed4d8833de4d7b57907e1cd4012',
    '0xb36e9e8218866df29a46ee3f2160e380b15bcb57',
    '0xc9930c8e4055bd5f4619dc345d142e7b814ff655',
    '0xcbf111366e51ca8e90180d0ee2c7fed9d2bd62dc',
    '0x3017c658c3fa05781e96599395325ea3f2350b93',
    '0x6fa7a51725800af7973be549ac5c5bb2c057de37',
    '0x7756525541d5cc50bd1c0dc1c99cc29ac3fc0d6b',
    '0x11604a4e849b9d4c6a459a6c69938fc9bc4054db',
    '0x1391bec69fa33ae796dce01fa12d194e449db65f',
    '0x589842e8ccd2367df47603189b1c2d3447d6734d',
    '0x62647118b8b13dd459232ae46c8feb157a054d1f',
    '0x38567f34e6154f923a96f1eca2cf42be9db1ef4d',
    '0xc2e44cc966a1a7538b31f84b119dc2a8b25a975d',
    '0x9bc11c78c3a2a157b01b2b4fe3c610bcc04ff84b',
    '0x3bb6051cdb5f3e399679dd20400a12919d7e65a9',
    '0xeca41677558025c76bfd20e9289283cb4ca85f46',
    '0x6da35093d7661418dc24606b5d3478cd1e64124d',
    '0x4db6ec655eb2b5bc9dda13e0cfa6df0a301c5ba8',
    '0xd77caf4c72c635e0ee0853694607cb567c06653d',
    '0x71232ead3bec1ac03ecfe2556acd6c7d0bd349c7',
    '0x77cc6ec00f1b6eb1a2a4a5a66e3199f0d99a2716',
    '0x67468a31b888f631b520db2299c4ccead4eed18f',
    '0x0ab57e2507efc52c1cf65fd829f638e82287638b',
    '0x3044392bc94888126fa00c6586dbe86000a64a02',
    '0x555672439e6f44b6d11abcbcac1e568377b82501',
    '0x7d04227808dd38f649fe272fe004c029b68f40d7',
    '0x3288cb2b35d4b25deb6c4e7f8042523b52eb48cd',
    '0xaa3d5ab57060e32546e45e7fdddbf482879a3609',
    '0x1ee0950a899d327e1041728a66fc140d859be81c',
    '0x5c91c5fd6851f9ed55dacb5d7eae87d9549b8d82',
    '0xb008c5a449bf9f36858846a2d1080120a2560216',
    '0x95340b69162d21a931da57cb9759b671ef57ee7f',
    '0xfaf55d4daf9e5377eb33a9eadde86d3a455f6c6d',
    '0xce57992a5a760ff983bfa5cc15415a18a50b3ecd',
    '0x798121bef71dafcff3d046e4c29893675f4ed503',
    '0x44a82977f3b357edfee04231077f990d291735c3',
    '0x951ed2323ad203e490353ab2ac8ee142fe4b771e',
    '0xbdcee87119265fc0ec27a3113efc3fbbb90d4d7b',
    '0x462cc75caee4d0be283eeddbc2cd5698b9880b91',
    '0x1b8027dc7a6a8949d1ba4aa1c1a81555c6ae2a3a',
    '0xfa6fd86b42ce2ec5e00c1cd35fb0c78bd88c0417',
    '0x14a6f964b3d904c918f740826c99dd6a481a95c8',
    '0x9406a41d8d845a2fca05f860d33f50c9195bdce3',
    '0x75b07e64161ace39d6eab54d25f25410ffd3d458',
    '0xbdb7f183b9684e51abce065ad2ebeb58c53b9705',
    '0x1aaea220580bfe9abd2c30a75cd1c5451c6a13b2',
    '0xa4ad15c9ee1bce28dec3fad031fc3b56e45928a0',
    '0xcbd0edbf33209e309b88c86772de17012d586fd6',
    '0x67fbbfc36c4276e4855658e4e3310abef7c6fcc0',
    '0x6130a038b17f7ee8d28abd54415f2066c0e81b0b',
    '0xe7d52be2217cc6178b661e4fe0e9e371ee18b630',
    '0xea8dd9381571d618119912af553ce81c3c0dff12',
    '0x98a581c72d4b5b47bd6348ea52d17963f68af33e',
    '0x2ec7947f3ac9aaaa44b53640506cdc9d848d9489',
    '0x86486edcb3ebc8f79db2d799b47cb833662740e8',
    '0xee4c6232735b63796446c837e7f4b8bd299fc844',
    '0xc5c510ae135b70a12a6be47d0e8ec072e9c62610',
    '0x787659052e92862f225b139060569083d6dff695',
    '0x96dc4580e02c10548bc2e170aa7d41725159c5c4',
    '0x2298718f8c34adb143bdcc017feae24de4a62653',
    '0x58ffe60904be609b4af1b42ce7bfaadfd0030cea',
    '0x6de754c0816aa1ec98d2ff55ad6ea7d00ab6e131',
    '0x5f9f19434824cc539ef0d3c0a386a9707cf2a2ec',
    '0xea313f37c177d780d5fc9760c5619b4466099a7b',
    '0xe1478cbf115c2d6bec1572e0e6a43a2dfadca15a',
    '0xa6ab73d38f67c873fc8e0d54c30a25ed4255b5cb',
    '0xe68705ee4f07365c31fb7927f7da89dfe710ec5b',
    '0x4e65a036fa1df22e6f108ecf256a25d3e6f6ea1d',
    '0xcdceff3dcf24d6286f8accab295abd5bd5f2ef11',
    '0xb87d9edb23e88bc7c1e6f2cd3aa65fae6397670f',
    '0x01734005354d569716291cd1cfbc67f3f56a0b6f',
    '0xc4b0259cf0252eb3b3cf61f41e055e6db34f644c',
    '0x70aecbb415372151c47e4d82a3263b6cc8ea1995',
    '0x6a6d6d904812b6d559c18eca37ffe366a6c29d9d',
    '0x23c6f263adfb998f16a3d95f9c0d4d2024003156',
    '0x880c15b9f264554effd3191010c99ae84b5b4876',
    '0x33d30dc6eae66f5a5f9ea54f6b54e3ab9cd9ba38',
    '0x607f625300099cbd4f8c7430f10112075a7102de',
    '0x2aaaf3e244b493f9f1e1a0ec17ff344109900842',
    '0x84609c7176b18487a2b09a2bc4e6db9f5b8e8e0e',
    '0x926132c52a4a050eabe0bc034b6233a2b37a96b3',
    '0xfbb447166a9826ae2cb5fb7ca86a2a66a17147be',
    '0x67f40170bf81d44017be25e6596d1df7e67538f8',
    '0xea3c39496c2868741ff8a32b49a2943309ead303',
    '0x7981889f6f7930a3a9f5cb8425cba6fd4991125c',
    '0xebda2d5559eec2fcd86a001a99955fdb75e206e4',
    '0x90755a465d1b711a6b68e81deb3e3e5fbcdc3d0c',
    '0x40f657e85bbb5cc7beb402da315c3fe1974442bf',
    '0x9fe7a3ed1f3734604c365e3d461e4c1644b1e8e2',
    '0xe6410ecbaa041a5dcde79164ca66de42a8d72bee',
    '0x552ec58ec4aa8b5374b042f2995fa857167c99af',
    '0x234d1f81f8c88d306ac5f7a67b0066a5214b35d5',
    '0x24e99405587ec0c52521c39159931b70f546e7d2',
    '0x5cbe5aeef459fcf20b3811f636ddd33f9aa1641e',
    '0xa3587c55f1d2f8458cbb70cfb432203ac566fffa',
    '0x0e5dc416760b2bd2c1ca953e437f7a58798531f1',
    '0xd220d187d5cc56ef262fb02f381a06a7bc69f3ee',
    '0x2d165e5191ef0674da2637b421a765bdb1f8d346', //  Community Manager and Italian ambassador.
    '0xc49b6c714feaf22b12eb02a01521dab6edd1fcbd',
    '0x6b418a0a93532a660fcfdfcbc74078dae1e43dec',
    '0x504361604c519a16cc19a628f3de1993996f2a7c',
    '0xc8d5e16e0638a7ed4c30cb92c3b0810d2d1c09ad',
    '0x2efdb0192db45f3a8bb8510613aff0303911b85b',
    '0x028ced475400006c4033d84ee6eb7dc9182d8855',
    '0xf0a355a5ab3d96a4347e9fe3052ef39357df90d0',
    '0x215a2a56984df578603f6f9f5a46750efb356894',
    '0x95f1872c2c63f54072bd42f68beee71e0d6f67d3',
];

const specialCommunityCommitter = [
    '0xf5d7d97b33c4090a8cace5f7c5a1cc54c5740930', // Russian lockdrop movie introducer.
    '0xa451fd5fcc0d389e0c27ff22845c0e17153f7dc8', // Community Manager.
    '0x3937B5F83f8e3DB413bD202bAf4da5A64879690F', // Korean lockdrop manual translator + community manager
];

const secondBtcIntroducer = ['add Bitcoin addresses'];

export const validEthAddressList = [
    ...firstEthIntroducer,
    ...secondEthIntroducer,
    ...specialCommunityCommitter,
    defaultAddress,
];

export const validBtcAddressList = [...secondBtcIntroducer];

/**
 * Checks if the given introducer address is listed as a valid introducer for the lockdrop
 * @param introducer a bitcoin or ethereum address
 * @param tokenType what blockchain network the given address belongs to. Default is ETH
 */
export function isValidIntroducerAddress(introducer: string, tokenType: 'BTC' | 'ETH' = 'ETH'): boolean {
    const validIntroducer =
        tokenType === 'ETH'
            ? validEthAddressList.find(i => introducer.toLowerCase().match(i.toLowerCase()))
            : validBtcAddressList.find(i => introducer.toLowerCase().match(i.toLowerCase()));
    return typeof validIntroducer === 'string';
}
